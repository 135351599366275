import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Reusable/Navbar/Navbar";
import "./CheckAudioVideoScreen.css";
import * as faceapi from "face-api.js";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const CheckAudioVideoScreen = ({ setShowLoginScreen}) => {
  const [audioTesting, setAudioTesting] = useState(false);
  const videoRef = useRef(null);
  const [stream, setStream] = useState();
  const [audioError, setAudioError] = useState(false);
  const [webcamError, setWebcamError] = useState(false);
  const [avError, setAvError] = useState(false);
  const [checkWebcamMicSuccess, setCheckWebcamMicSuccess] = useState(false);
  const [startTesting, setStartTesting] = useState(true);
  const { transcript } = useSpeechRecognition();
  const buttonRef = useRef();
  const [multipleFacesDetected, setMultipleFacesDetected] = useState();
  const [cameraError,setCameraErrorMessage] = useState(false)

  const [audioTestingSuccess, setAudioTestingSuccess] = useState(false);
  let timer;
  let speechRecognitionTimeOut;
  const [transcriptText, setTranscriptText] = useState("");

  useEffect(() => {
    const checkAudioVideo = sessionStorage.getItem("checkAudioVideo");
    const userAgent = navigator.userAgent.toLowerCase();

    let mobileView = false
    if (/android|iphone|ipad|ipod/.test(userAgent)) {
      mobileView= true
    } else {
      mobileView= false

    }
    console.log("mobileView",mobileView)
    if(!mobileView){
      if (!checkAudioVideo) {
        getCameraPermission();
        loadModels();
        handleSpeechRecognisationStart();
      }
    }
  
  }, []);

  useEffect(() => {
    setTranscriptText(transcript);
  }, [transcript]);

  useEffect(() => {
    if (webcamError && audioError && !checkWebcamMicSuccess) {
      setAvError(true);
      setAudioError(false);
      setWebcamError(false);
      setMultipleFacesDetected(false);
    }
  }, [webcamError, audioError, checkWebcamMicSuccess]);

  const getCameraPermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = { video: true, audio: false };
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        setStream(videoStream);
        if (videoStream && videoRef.current) {
          videoRef.current.srcObject = videoStream;
        }
      } catch (err) {
        if(err.message === 'Device in use'){
          setCameraErrorMessage(true)
      
        }
      }
    } else {
      console.log("The MediaRecorder API is not supported in your browser.");
    }
  };

  const loadModels = async () => {
    // LOADING THE FACE DETECTION MODELS FROM PUBLIC/MODELS DIRECTORY
    await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
    await faceapi.nets.faceExpressionNet.loadFromUri("/models");
  };

  // const checkSubset = (parentArray, subsetArray) => {
  //   return subsetArray.every((element) => {
  //     return parentArray.includes(element)
  //   })
  // }

  const speechComparison = () => {
    // Define a regular expression to remove punctuation
    const punctuationRegex = /[.,]/g;
  
    // Log the transcript text for debugging purposes
    // console.log("transcriptText", transcriptText);
  
    // Remove punctuation and split the text into words, then convert to lowercase
    const cleanedTranscript = transcriptText
      .replace(punctuationRegex, "")
      .toLowerCase()
      .split(" ");
  
    // Log the cleaned transcript for debugging purposes
    // console.log("cleanedTranscript", cleanedTranscript);
  
    // Define the required keywords for comparison
    const requiredKeywords = ["testing", "microphone", "clear", "audio"];
  
    // Check if all required keywords are present in the transcript
    const allKeywordsPresent = requiredKeywords.every(keyword =>
      cleanedTranscript.includes(keyword)
    );
  
    if (allKeywordsPresent) {
      clearInterval(timer);
      setAudioTestingSuccess(true);
      setCheckWebcamMicSuccess(true);
  
      setAudioError(false);
      setMultipleFacesDetected(false);
      setWebcamError(false);
      setAudioTesting(false);
    } else {
      clearInterval(timer);
      setAudioError(true);
      setAudioTesting(false);
      setAudioTestingSuccess(false);
      setCheckWebcamMicSuccess(false);
    }
  };

  const handleFaceDetectionAndSpeechRecognition = async (retry) => {
    if (retry) {
      clearTimeout(speechRecognitionTimeOut);
      setAudioError(false);
      setWebcamError(false);
      setAudioTesting(true);
      setStartTesting(false);
      setMultipleFacesDetected(false);
      setAvError(false);
    }
    setAudioTesting(true);
    setStartTesting(false);

    speechRecognitionTimeOut = setTimeout(() => {
      buttonRef.current.click();
    }, 10000);

    timer = setInterval(async () => {
      try {
        if (videoRef.current) {
          const detections = await faceapi.detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions()
          );
          if (detections && detections.length === 1) {
            const face = detections[0];
            const score = face._score;
            if (score < 0.4) {
              setWebcamError(true);
              setCheckWebcamMicSuccess(false);
              setMultipleFacesDetected(false);
              clearInterval(timer);
              clearTimeout(speechRecognitionTimeOut);
            }
          } else if (detections && detections.length > 1) {
            setMultipleFacesDetected(true);
            setWebcamError(true);
            setAudioTestingSuccess(false);
            setCheckWebcamMicSuccess(false);
            clearInterval(timer);
            clearTimeout(speechRecognitionTimeOut);
          } else {
            setMultipleFacesDetected(false);
            setWebcamError(true);
            setAudioTestingSuccess(false);
            setCheckWebcamMicSuccess(false);
            clearInterval(timer);
            clearTimeout(speechRecognitionTimeOut);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 5000);

    setTimeout(() => clearInterval(timer), 10000);
  };

  const handleShowLogin = () => {
    stream.getTracks().forEach((track) => track.stop());
    handleSpeechRecognisationStop();
    clearInterval(timer);
    sessionStorage.setItem("checkAudioVideo", true);
    setShowLoginScreen(true);
  };

  //Start Speech Recognisation
  const handleSpeechRecognisationStart = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-IN",
    });
  };

  //Stop Speech Recognisation
  const handleSpeechRecognisationStop = () => {
    SpeechRecognition.stopListening();
  };

  return (
    <div>
      <Navbar />
      <div className="test-main-container">
        <div className="webcam-mic-text">Webcam & Microphone Test</div>
        {cameraError && (
          <p style={{ color: "red" }}>
            <strong>
              The camera is currently being used by another application. Please
              close it and try again.
            </strong>
          </p>
        )}
        <div className="test-video-container">
          <video
            className="test-video"
            ref={videoRef}
            autoPlay
            playsInline
          ></video>
          {audioTesting && !webcamError && !audioError ? (
            <>
              <div className="alert-text" style={{ fontSize: "19px" }}>
                PLEASE READ BELOW SENTENCE TO COMPLETE MIC TEST
              </div>
              <div className="read-text" style={{ fontSize: "17px" }}>
                "Testing the microphone for clear audio."
              </div>
            </>
          ) : (
            ""
          )}
          {audioError ? (
            <>
              <div className="alert-text">can't hear you clearly</div>
              <div className="info-text">Please check your microphone</div>
            </>
          ) : (
            ""
          )}
          {checkWebcamMicSuccess && audioTestingSuccess ? (
            <>
              <div className="success-text">success</div>
              <div className="info-text">
                Camera & Microphone is working fine. Click on continue button to
                start the interview.
              </div>
            </>
          ) : (
            ""
          )}
          {webcamError && !multipleFacesDetected ? (
            <>
              <div className="alert-text">can't see you clearly</div>
              <div className="info-text">
                Please check your webcam / clean your webcam lens / check your
                room lighting
              </div>
            </>
          ) : (
            ""
          )}
          {multipleFacesDetected && !checkWebcamMicSuccess ? (
            <>
              <div className="alert-text">More than one person detected</div>
              {/* <div className='info-text'>Please ensure that you are the only person attending the interview</div> */}
            </>
          ) : (
            ""
          )}

          {avError && (
            <>
              {" "}
              <div className="alert-text">
                can't hear you and see you clearly
              </div>{" "}
              <div className="info-text">
                Please check your microphone and webcam{" "}
              </div>
            </>
          )}
        </div>

        <div className="test-btn-container">
          {startTesting && !audioError && !webcamError ? (
            <button
              className="test-screen-btn start-testing-btn"
              onClick={() => handleFaceDetectionAndSpeechRecognition()}
            >
              Start Testing
            </button>
          ) : (
            ""
          )}
          {audioTesting && !audioError && !webcamError ? (
            <button className="listening-btn test-screen-btn btn-progress  mb-1">
              <span>Listening</span>
            </button>
          ) : (
            ""
          )}
          {((audioError || webcamError) && !checkWebcamMicSuccess) ||
          avError ? (
            <button
              className="test-screen-btn retry-btn"
              onClick={() => handleFaceDetectionAndSpeechRecognition(true)}
            >
              Retry
            </button>
          ) : (
            ""
          )}
          {checkWebcamMicSuccess ? (
            <button
              className="test-screen-btn success-btn mb-1"
              onClick={() => handleShowLogin()}
            >
              Continue
            </button>
          ) : (
            ""
          )}
        </div>
        <button
          ref={buttonRef}
          onClick={() => speechComparison()}
          className="speech-compare-btn"
        >
          speech compare
        </button>
      </div>
    </div>
  );
};

export default CheckAudioVideoScreen;

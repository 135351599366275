
import { API } from "../global";

export function customTheme() {
  const domainName = window.location.pathname;
  const parts = domainName.split("/");

  // Extract the UUID and the string
  const UUID = parts.indexOf("login") + 1;
  const id = UUID + 1;

  let secondId = parts[id];
  let firstId = parts[UUID]

      if(secondId === 'interview' ){
        firstId = localStorage.getItem('testId')
        secondId=null
      }

  fetch(`${API}/candidate/tenant/get-tenant-details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body:JSON.stringify({interview_id :secondId ? secondId : firstId})
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.success) {
        const theme = response.result.tenant_theme_settings;
        const interviewData = response.result.tenant_interview_settings
        localStorage.setItem("logoUrl", theme.logo_url);
        localStorage.setItem('waitingDuration',interviewData.max_wait_duration_after_qn_read)
        localStorage.setItem('faceDetection',interviewData.face_detection)
        localStorage.setItem('tabDetection',interviewData.fraud_detection)
        localStorage.setItem('themeData',JSON.stringify(theme))
        setThemeData(theme)
      }
    });


 const setThemeData =(themeData)=>{
    document.documentElement.style.setProperty("--primary-color",themeData?.theme_primary);
    document.documentElement.style.setProperty("--primary-shade1",themeData?.theme_color1);
    document.documentElement.style.setProperty("--primary-shade2",themeData?.theme_color2);
    document.documentElement.style.setProperty("--primary-shade3",themeData?.theme_color3);
    document.documentElement.style.setProperty("--primary-shade4",themeData?.theme_color4);
    document.documentElement.style.setProperty("--primary-btn",themeData?.btn_primary);
    document.documentElement.style.setProperty("--secondary-btn",themeData?.btn_secondary);
 }
  

}

import React from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './components/Home/Home';
// import PreviewScreen from './components/PreviewScreen/PreviewScreen';
import InterviewScreen from './components/InterviewScreen/InterviewScreen';
import ThankYouScreen from './components/ThankYouScreen/ThankYouScreen';
import ErrorScreen from './components/ErrorScreen/ErrorScreen';
// import CheckAudioVideoScreen from './components/CheckAudioVideoScreen/CheckAudioVideoScreen';
import LicenceAgreementScreen from './components/LicenseAgreementScreen/LicenseAgreementScreen';
import InstructionScreen from './components/InstructionScreen/InstructionScreen';
// import LandingPage from './components/Login/LandingPage';
import QuestionScreen from './components/InterviewScreen/QuestionScreen';
import UploadError from './components/ErrorScreen/UplaodError';
import Login from './components/Login/Login';

function RoutesList() {
      const domainName = window.location.pathname;
      const parts = domainName.split("/");
    
      // Extract the UUID and the string
      const UUID = parts.indexOf("login") + 1;
      const id = UUID + 1;

      const secondId = parts[id];

      return(
      <Routes>
            <Route path='/' element={<Home />} />
            {secondId ? (
            <Route path='/interview/login/:tenantId/:testid' element={<InstructionScreen />} />

            ) : (
            <Route path='/interview/login/:testid' element={<InstructionScreen />} />
            )}
            {/* <Route path='/interview/login/:tenantId/:testid' element={<Login />} /> */}
            <Route path='/interview/license_agreement/:testid/' element={<LicenceAgreementScreen />} />
            <Route path='/interview/login/prepare/:tenantId/:testid' element={<Login />} />
            <Route path='/interview/interview' element={<InterviewScreen />} />
            <Route path='/interview/questions' element={<QuestionScreen/>}/>
            <Route path='/interview/thankyou' element={<ThankYouScreen />} />
            <Route path='/interview/error' element={<ErrorScreen />} />
            <Route path='/interview/upload-error' element={<UploadError/>}/>
      </Routes>
      )
     
    }
    
    export default RoutesList;


import React, { useEffect } from "react";
import Navbar from "../Reusable/Navbar/Navbar";
import "./InstructionScreen.css";
import error from "../../assets/error.svg";
import { useNavigate, useParams } from "react-router";
import { API } from "../../global";
import { customTheme } from "../../CustomStyle/CustomTheme";
import { useDispatch } from "react-redux";
import { setTenantData } from "../../Redux/Actions/tenantDetailsAction";

const InstructionScreen = () => {
  // const [startInterviewBtnDisable, setStartInterviewBtnDisable] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { tenantId,testid } = useParams();
  useEffect(() => {
    localStorage.setItem('tenantId',tenantId)
    localStorage.removeItem('showLicense')
    localStorage.setItem('testId',testid)
    sessionStorage.removeItem("checkAudioVideo");
    // setShowLogin(JSON.parse(localStorage.getItem('showLogin')))
    // const showLicensePage =  JSON.parse(localStorage.getItem('showLicense'))
    // setShowLicense(showLicensePage)
    customTheme()
    
    fetch(`${API}/candidate/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify({interview_id : testid})
    })
      .then((res) => res.json())
      .then((response) => {
        if(response.success){
          dispatch(setTenantData(response))
            const theme = response.result.tenant_theme_settings;
            const interviewData = response.result.tenant_interview_settings
            localStorage.setItem('waitingDuration',interviewData.max_wait_duration_after_qn_read)
            localStorage.setItem('faceDetection',interviewData.face_detection)
            localStorage.setItem('tabDetection',interviewData.fraud_detection)
            localStorage.setItem('themeData',JSON.stringify(theme))
            localStorage.setItem("logoUrl", theme.logo_url);
            localStorage.setItem("tenant_id", response.result._id);
            // setLicenseContent(response.result.tenant_policy_documents)
        }
       
      });

      // eslint-disable-next-line
  }, []);

  const interviewInstructions = [
    "Before starting the interview, please ensure that your microphone and camera are working properly and that access has been granted.",
    "Ensure your internet connection is strong enough for seamless video uploads.",
    "Make sure your device is capable of smooth video recording without interruptions.",
    "Find a quiet, well-lit location with a professional background.",
    "Dress professionally.",
    "Take your time and pause briefly before recording each response.",
    "Maintain eye contact with the camera while speaking.",
    "Please note, once you answer a question, you will not be able to go back to the previous question.",
    "Avoid distractions by turning off notifications and ensuring you won’t be interrupted.",
    "A screen will display each question before automatically moving to the recording screen after a brief time, so stay ready to respond."
  ];

  const navigateToLogin =()=>{
    navigate(`/interview/login/prepare/${tenantId}/${testid}`)
    // handleLogin()
    // localStorage.setItem('showLogin',true)
  }

  return (
    <div>
      <Navbar />
      <div className="instruction-main-container">
        <div className="instruction-inner-container px-4">
          <div className="read-instruction-container">
            <img src={error} alt="error" />
            <div className="read-instruction-text">
              Read the instructions carefully before starting the interview
            </div>
          </div>
          <div>
            <ol className="instructions-container">
              {interviewInstructions.map((instruction, index) => (
                <li key={index}>{instruction}</li>
              ))}
            </ol>
          </div>

          <div className="instruction-btn-container">
            <button
              className="start-interview-btn-enabled"
              style={{
                border: "none",
                background: "#33333380 0% 0% no-repeat padding-box",
                color: "white",
                borderRadius: "40px 8px 8px 40px",
              }}
              // disabled={startInterviewBtnDisable ? true : false}
              onClick={navigateToLogin}
              // onClick={() => navigate("/interview/questions")}
            >
              <span className="start-interview-btn">
                <span className="start-interview-btn-circle">
                  <span className="start-interview-btn-dot-enabled start-interview-btn-dot"></span>
                </span>
                <span style={{ paddingLeft: 5, paddingRight: 5 }}>
                  Start Interview
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionScreen;

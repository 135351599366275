import React, { useEffect } from 'react'
import "./ErrorScreen.css"
import Navbar from '../../components/Reusable/Navbar/Navbar'
import warning from "../../assets/Warning.png"
import { useNavigate } from 'react-router'

export default function ThankYouScreen({mediaStream}) {
  // console.log(props);
  // console.log(props.stream);
  const navigate = useNavigate();

  const removePermission = async () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
      track.stop();
     
      });
      // props.liveVideoFeed={}
    }
    // props.liveVideoFeed = {}
  };

  useEffect(()=>{
 
  removePermission();

  if(localStorage.getItem('cameraoff') === null || localStorage.getItem('cameraoff') === undefined ){
    localStorage.setItem('cameraoff',"removecamera");
    window.location.reload()
  }


  sessionStorage.clear();  
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = ''; // Required for Chrome
  };

  const handlePopstate = () => {
    navigate(window.location.pathname); // Navigate back to the current page
  };

  window.history.pushState(null, null, window.location.pathname);
  window.addEventListener('beforeunload', handleBeforeUnload);
  window.addEventListener('popstate', handlePopstate);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('popstate', handlePopstate);
  };
   // eslint-disable-next-line
  },[navigate])


  return (
    <>
   <Navbar />
  <div className="container">
    <div className="row">
      <div
        className="col-lg-12"
        style={{ display: "flex", justifyContent: "center",height:"90vh",alignItems:"center" }}
      >
        <div className="thankyou-screen-container">
          <div className="panel-heading" style={{ textAlign: "center" }}>
            <img
              src={warning}
              className=""
              alt="talentio_error"
              style={{ marginBottom: 20, width: "15%" }}
            />
            <h3 className="panel-title">
              Something went wrong!
            </h3>
            <p style={{ marginTop: 20 }}>
            We apologize for the inconvenience. 
            </p>
            <p style={{ marginTop: 5 }}>
            It seems we've encountered a technical hiccup during your online interview.Our team is already aware of the issue and is actively working to resolve it.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> 

    </>
  )
}

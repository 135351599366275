import { combineReducers } from "redux";
import tenantDetailsReducer from './Reducers/tenantDeatilsReducers';
import questionDetailsReducer from "./Reducers/questionDetailsReducer";


const rootReducer = combineReducers({
  tenantDetails: tenantDetailsReducer,
  questionDetails : questionDetailsReducer
  // other reducers
});

export default rootReducer;
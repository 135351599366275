import { useEffect, useState } from "react";
// import ignitho from "../../../assets/Ignitho - White Logo - Transparent BG.png";
import talentou from "../../../assets/Talentou-Logo-nav.svg";
import "./Navbar.css";

export default function Header() {
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    setLogoUrl(localStorage.getItem("logoUrl"));
  }, []);
  return (
    <>
      <header id="header" className="navbar-head">
        <nav id="navbar" className="navbar">
          <div className="container-nav">
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="Ignitho - White Logo - Transparent BG"
                style={{ width: "85px", height: "50px", objectFit: "contain" }}
              />
            ) : (
              <img
                src={talentou}
                alt="Ignitho - White Logo - Transparent BG"
                style={{ width: "85px", height: "50px", objectFit: "contain" }}
              />
            )}
            {logoUrl && (
              <p style={{ margin: "0" }}>
                {" "}
                <img
                  src={talentou}
                  alt="Talentio_logo.png"
                  style={{ width: "80px" }}
                  className="ml-4 img-fluid"
                />
              </p>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}

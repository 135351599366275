import React, { useEffect, useState, useLayoutEffect } from "react";
import "./LicenseAgreementScreen.css";
// import ignitho from "../../assets/Ignitho - White Logo - Transparent BG.png"
// import talentio from "../../assets/Talentio_logo.svg"
import { useNavigate } from "react-router";
import Navbar from "../Reusable/Navbar/Navbar";
import { useSelector } from "react-redux";

export default function LicenceAgreementScreen({ licenseContent }) {
  const navigate = useNavigate();
  let tenantData = useSelector((state) => state.tenantDetails?.tenantData);
  tenantData = tenantData?.result?.tenant_policy_documents
  const [agree, setAgree] = useState(false);
  const handleChange = () => {
    setAgree((current) => !current);
  };
  const [canId, setCanId] = useState("");
  const [scheduleId, setScheduleId] = useState("");

  // console.log("licenseContent", licenseContent);
  useEffect(() => {
    let url = window.location.href;
    setCanId(url.split("/")[5]);
    setScheduleId(url.split("/")[6]);
    // console.log(url, "url");
    // const handleBeforeUnload = (event) => {
    //   event.preventDefault();
    //   event.returnValue = '';
    // };

    // const handlePopstate = () => {
    //   navigate(window.location.pathname);
    // };

    // window.history.pushState(null, null, window.location.pathname);
    // window.addEventListener('beforeunload', handleBeforeUnload);
    // window.addEventListener('popstate', handlePopstate);

    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    //   window.removeEventListener('popstate', handlePopstate);
    // };
  }, [navigate]);

  useLayoutEffect(() => {
    // const handleBeforeUnload = (event) => {
    //   event.preventDefault();
    //   event.returnValue = '';
    // };
    // window.addEventListener('beforeunload', handleBeforeUnload);
    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);

  return (
    <>
      <Navbar />
      <div className="container licence-main-container">
        <div className="row" style={{ height: "90%" }}>
          <div className="col-lg-12 agreement-main-container">
            <div className="agreement-container">
              <div className="panel-heading">
                <h5 className="panel-title">{tenantData?.title}</h5>
              </div>
              <div className="agreement-body">
                <div
                  dangerouslySetInnerHTML={{ __html: tenantData?.content }}
                />
              </div>
              <form>
                <fieldset>
                  <div className="agree-checkbox-card">
                    <input
                      type="checkbox"
                      id="agree"
                      name="agree"
                      defaultValue="agree"
                      onChange={handleChange}
                    />
                    <label htmlFor="agree" style={{ marginLeft: "5px" }}>
                      I have read and accept the terms and conditions of the
                      EULA
                    </label>
                  </div>
                  <div className="eula-buttons-container">
                    <button
                      type="submit"
                      onClick={() =>
                        navigate(`/interview/questions`)
                      }
                      disabled={agree ? false : true}
                      className={`${agree ? "submit-btn btn" : "agree-btn"}`}
                    >
                      I Agree
                    </button>
                    <button
                      type="cancel"
                      onClick={() =>
                        navigate(`/interview/login/${canId}/${scheduleId}`)
                      }
                      className="btn cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { SET_TENANT_DETAILS } from "../Actions/tenantDetailsAction";

const initialState = {
    tenantData: null,
};

const tenantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TENANT_DETAILS:
      return {
        ...state,
        tenantData: action.payload,
      };
    default:
      return state;
  }
};

export default tenantDetailsReducer;

import { GET_QUESTION_DETAILS } from "../Actions/questionDetailsAction";

const initialState = {
    questionData: null,
};

const questionDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_DETAILS:
      return {
        ...state,
        questionData: action.payload,
      };
    default:
      return state;
  }
};

export default questionDetailsReducer;

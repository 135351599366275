import "./Home.css";

const Home = () => {

    return (
        <div>
            <h3 className="text-center">Welcome to Talentou</h3>
        </div>
    );
};
export default Home;
// import './App.css';
import { Toaster } from 'react-hot-toast';
import RoutesList from "./routes"
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UploadQueueProvider } from './providers/UploadQueueContext';

// import './Styles/sassStyles.css';
// import './Styles/sasColors'

// import {customTheme } from './CustomStyle/CustomTheme';


function App() {
  const navigate = useNavigate();
  // customTheme()
  useEffect(() => {
    const handlePopstate = () => {
      if (window.confirm("If you go back you will be logged out from taking the interview. Are you sure ?")) {
        navigate('/');
      }
    };
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <UploadQueueProvider>
      <div className="App">
        <Toaster />
        <RoutesList />
      </div>
    </UploadQueueProvider>
  );
}

export default App;

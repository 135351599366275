import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./confirmModal.css";

import { useFormik } from "formik";
import { useParams } from "react-router";
import { API } from "../../global";
import toast from "react-hot-toast";

const ConfirmModal = (props) => {
  const { testid } = useParams();
  const otpBtnRef = useRef(null);
  const [loading, setLoading] = useState(false);
  
  // validation function for OTP
  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    }
    return errors;
  };

  // value initialization and posting the user verification function
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      resendOtp({ email: values.email });
    },
  });

  const resendOtp = (data) => {
   setLoading(true)
    const objOtp = {
      email: data.email,
      interview_schedule_id: testid,
      tenant_id : localStorage.getItem('tenant_id')
    };

    fetch(`${API}/candidate/auth/resend-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objOtp),
    })
      .then((res) => res.json())
      .then((result) => {
   setLoading(false)

        if (result.success === true) {

          toast.success(result.info.msg, {
            autoClose: 5000,
            className: "custom-toast-error",
          });
        } else {
          toast.error(result.error.msg, {
            autoClose: 10000,
            className: "custom-toast-error",
          });
        }
        props.handleCloseModal();
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger button click event
      otpBtnRef.current.click();
    }
  };
  const cancel = () => {
    props.handleCloseModal();
  };

  return (
    <div>
      {/* <Toaster /> */}
      <div className="panel-heading" style={{ textAlign: "center" }}>
        <h3 className="panel-title">Resend OTP</h3>
        <p className="forgot-password-sub">
        Enter your email, and we'll send you a new OTP to verify your account.
        </p>
      </div>
      <Row>
        <Col>
          <div className="form-group">
            <form onSubmit={formik.handleSubmit}>
              <input
                className="form-control"
                placeholder="Enter your E-Mail ID"
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onKeyDown={handleKeyPress}
              />
              {formik.errors.email && formik.touched.email ? (
                <div style={{ color: "red" }}>{formik.errors.email}</div>
              ) : null}

              <div className="button-container d-flex justify-content-end gap-2">
                <button type="submit" className="confirm-btn" ref={otpBtnRef}>
                  {loading ? "Submitting.." : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={cancel}
                  className="otp-cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmModal;
